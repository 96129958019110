<template>
  <div>
    <b-card-actions title="Filters" action-collapse>
      <div>
        <!-- search input -->
        <template v-if="$permissionAbility(ATTENDANCE_SEARCH, permissions)">
          <div>
            <b-row>
              <b-col
                  md="6"
                  lg="4"
                  xs="12"
              >
                <v-select
                    v-model="selectEmpId"
                    :options="filterEmpIdOption"
                    :reduce="(item) => item.id"
                    label="name"
                    placeholder="Select Employee"
                    class="mb-1 custom-font"
                    @input="loadAttendance('user')"
                >
                  <template #option="data">
                    <UserSelect :user="data" />
                  </template>
                </v-select>
              </b-col>
              <b-col
                  md="6"
                  lg="4"
                  xs="12"
              >
                <v-select
                    v-model="selectStatusId"
                    :options="filterStatusIdOption"
                    :reduce="(item) => item.id"
                    label="name"
                    placeholder="Select Status"
                    class="mb-1 custom-font"
                    @input="loadAttendance('status')"
                />
              </b-col>
              <b-col
                md="6"
                lg="4"
                xs="12"
              >
                <v-select
                  v-model="selectDivisionId"
                  :options="filterDivisionIdOption"
                  :reduce="(item) => item.id"
                  label="name"
                  placeholder="Select Division"
                  class="mb-1 custom-font"
                  @input="loadAttendance('division')"
                />
              </b-col>
              <b-col
                md="6"
                lg="4"
                xs="12"
              >
                <v-select
                  v-model="selectDepartmentId"
                  :options="filterDepartmentIdOption"
                  :reduce="(item) => item.id"
                  label="name"
                  placeholder="Select Department"
                  class="mb-1 custom-font"
                  @input="loadAttendance('department')"
                />
              </b-col>
              <b-col
                  md="6"
                  lg="4"
                  xs="12"
              >
                <v-select
                    v-model="selectEmpTypeId"
                    :options="filterEmpTypeIdOption"
                    :reduce="(item) => item.id"
                    label="name"
                    placeholder="Select Employee Types"
                    class="mb-1 custom-font"
                    @input="loadAttendance('empType')"
                />
              </b-col>
              <b-col
                md="2"
                lg="4"
                xs="12"
              >

                <flat-pickr
                  v-model="filterRangeDate"
                  class="form-control"
                  placeholder="Select Date Range"
                  :config="{ mode: 'range', defaultDate: [filterStartDate, filterEndDate]}"
                  @input="loadAttendance('date')"
                />
              </b-col>
            </b-row>
          </div>
        </template>

        <template v-else>
          <div class="mb-2">
            <b-row>
              <b-col
                md="2"
                lg="2"
                xs="12"
              >

                <flat-pickr
                  v-model="filterRangeDate"
                  class="form-control"
                  placeholder="Enter Date Range"
                  :config="{ mode: 'range', defaultDate: [this.filterStartDate, this.filterEndDate]}"
                  @input="loadAttendance('date')"
                />
              </b-col>
            </b-row>
          </div>
        </template>
      </div>
    </b-card-actions>

    <b-card>
      <div>

        <!-- table -->
        <vue-good-table
          style-class="vgt-table table-custom-style striped"
          mode="remote"
          :total-rows="totalRecords"
          :is-loading.sync="isLoading"
          :rows="rows"
          :sort-options="{
            enabled: false,
            multipleColumns: true,
            initialSortBy: [{ field: 'date', type: 'desc' }],
          }"
          :columns="columns"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: head -->
            <span v-if="props.column.field === 'name'">

              <template v-if="props.row?.user_id">

                <span class="cursor-pointer" @click="onShowPage(props.row.id)">
                  <UserCard :user="props?.row?.user?.data" />
                </span>

              </template>

            </span>

            <span
              v-if="props.column.field === 'format_check_in_out'"
              class="font-weight-bold"
            >
              <!-- first_check_in -->
              <template v-if="props.row?.first_check_in">
                <div>
                  <span>
                    Check In : <b>
                      {{ formatFnTableCheckIn(props.row?.first_check_in) }}
                    </b>
                  </span>
                </div>
              </template>

              <!-- last_check_out -->
              <template v-if="props.row?.last_check_out">
                <div>
                  <span>
                    Check Out : <b>
                      {{ formatFnTableCheckIn(props.row?.last_check_out) }}
                    </b>
                  </span>
                </div>
              </template>
            </span>

            <span v-if="props.column.field === 'format_extra_less_duty_minutes'">
              <template v-if="props.row?.extra_less_duty_minutes < 0">
                <div>
                  <b-badge
                    variant="light-danger"
                    pill
                  >
                    {{ formatFnTableExtraLessDutyHours(props.row?.extra_less_duty_minutes) }}
                  </b-badge>
                </div>
              </template>
              <template v-else-if="parseInt(props.row?.extra_less_duty_minutes) === 0">
                <div>
                  <b-badge
                    variant="light-info"
                    pill
                  >
                    00:00
                  </b-badge>
                </div>
              </template>
              <template v-else>
                <div>
                  <b-badge
                    variant="light-success"
                    pill
                  >
                    {{ formatFnTableExtraLessDutyHours(props.row?.extra_less_duty_minutes) }}
                  </b-badge>
                </div>
              </template>
            </span>

            <span v-if="props.column.field === 'status'">
              <template v-if="props.row?.attendanceStatus?.data?.name">
                <b-badge
                  class="badge badge-glow"
                  :style="{ color: 'white', backgroundColor: ( props.row.attendanceStatus.data.color_code ?? '#7367f0' ) }"
                >
                  {{ props.row?.attendanceStatus?.data?.name }}
                </b-badge>
              </template>

              <template v-if="props.row?.half_day_leave">
                <div class="mt-1">
                  <b-badge
                    variant="light-danger"
                    pill
                  >
                    {{ props.row?.half_day_leave | strSnakeToPascal }} Leave
                  </b-badge>
                </div>
              </template>
          </span>

            <!-- Column: Action -->
            <span
              v-if="props.column.field === 'action'"
              @click="onShowPage(props.row.id)"
              @contextmenu.prevent="onOpenInNewTab(props.row.id)"
            >
              <template v-if="$permissionAbility(ATTENDANCE_SHOW, permissions)">
                <feather-icon
                  v-b-tooltip.hover
                  icon="EyeIcon"
                  class="mr-50 custom-icon cursor-pointer"
                  title="View"
                  size="16"
                />
              </template>
            </span>


            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Showing 1 to </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['10', '25', '50', '100', '500']"
                  class="mx-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </b-card>
  </div>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import flatPickr from 'vue-flatpickr-component'
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BSpinner,
  BCol,
  BRow,
  BFormDatepicker,
  BFormTextarea,
  BLink,
  VBTooltip,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, max } from "@validations";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import {
  ATTENDANCE_SHOW,
  ATTENDANCE_SEARCH,
  ATTENDANCE_RECONCILIATION,
} from '@/helpers/permissionsConstant'
import { formatDateRange, minutesToHours } from '@/helpers/helpers'
import { EventBus } from '@/helpers/event-bus'
import strSnakeToPascal from '@/filter/strSnakeToPascal'
import UserCard from '@/layouts/components/UserCard.vue'
import UserSelect from '@/layouts/components/UserSelect.vue'

export default {
  name: 'AttendanceView',
  components: {
    UserSelect,
    BCardActions,
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
    BCol,
    BRow,
    BFormDatepicker,
    BFormTextarea,
    flatPickr,
    BLink,
    UserCard,
    VBTooltip,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      // filter
      filterEmpTypeIdOption: [],
      selectStatusId: "",
      selectEmpTypeId: "",
      filterStatusIdOption: [],
      filterDivisionIdOption: [],
      selectDivisionId: "",
      filterDepartmentIdOption: [],
      selectDepartmentId: "",
      filterEmpIdOption: [],
      selectEmpId: "",
      filterStartDate: this.getCurrentDate(),
      filterEndDate: this.getCurrentDate(),
      todaysDate: "",

      filterRangeDate: this.getCurrentDate(),

      attendanceId: "",
      isAttendanceReconciliationFormSubmitLoading: false,
      reconciliationNote: "",

      ATTENDANCE_SHOW,
      ATTENDANCE_RECONCILIATION,
      ATTENDANCE_SEARCH,
      modelType: '',
      departmentId: '',
      name: '',
      selectHeadId: '',
      headIdOption: [],
      divisionIdOption: [],

      pageLength: 10,
      columns: [
        {
          label: 'Date',
          field: 'date',
          formatFn: this.formatDate,
          sortable: true,
        },
        {
          label: 'Name',
          field: 'name',
          sortable: false,
        },

        {
          label: 'Status',
          field: 'status',
          sortable: false,
        },
        {
          label: 'Check In/Check Out',
          field: 'format_check_in_out',
          formatFn: this.formatFnTableCheckIn,
          sortable: false,
        },
        {
          label: 'Expected Duty Hours',
          field: 'expected_duty_minutes',
          sortable: false,
          formatFn: this.formatFnTableExpectedDutyHours,
        },
        {
          label: 'Actual Worked Hours',
          field: 'total_worked_minutes',
          formatFn: this.formatFnTableTotalWorkedMinutes,
        },
        {
          label: 'Extra/Less Duty Hours',
          field: 'format_extra_less_duty_minutes',
          sortable: false,
          formatFn: this.formatFnTableExtraLessDutyHours,
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      isAttendanceLogGenerating: false,
      isAttendanceReportGenerating: false,
      rows: [],
      delayTimer: null,
      isLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [{ field: 'first_check_in', type: 'desc' }],
        page: 1,
        perPage: 10,
      },
    }
  },

  computed: {
    ...mapGetters({
      permissions: 'userModule/getPermissions',
    }),
  },

  async created() {

    const todaysDate = this.getCurrentDate()

    this.filterStartDate = todaysDate
    this.filterEndDate = todaysDate
    this.filterRangeDate = `${this.filterStartDate} to ${this.filterEndDate}`

    if(this.$permissionAbility(ATTENDANCE_SEARCH, this.permissions)){
      this.selectStatusId = 'present'
    }

    EventBus.$on('changed-check-in-out', () => {
      if (this.$route?.name === 'admin-attendance') {
        this.loadItems()
      }
    })

    try {

      const [attendanceStatus, employeeTypes, divisions, departments, employees] = await Promise.all([
        this.getAttendanceStatus(),
        this.getEmployeeTypes(),
        this.getDivisions(),
        this.getDepartments(),
        this.getActiveEmployees(),
      ])

      // attendance status
      this.filterStatusIdOption = (attendanceStatus?.data?.data || []).map(
        item => {
          const name = `${item?.name ?? ''} (${item?.sort_name ?? ''})`
          return {
            name,
            id: item?.id,
          }
        },
      )
      const newAttendanceStatus = [
        {
          name: 'Present',
          id: 'present',
        },
        {
          name: 'Half Day Leave (HFL)',
          id: 'half_day_leave',
        },
      ]

      // Merging both arrays
      this.filterStatusIdOption = [...newAttendanceStatus, ...this.filterStatusIdOption]

      // employee type
      this.filterEmpTypeIdOption = (employeeTypes?.data?.data || []).map(
        item => {
          const name = item?.name
          return {
            name,
            id: item?.id,
          }
        },
      )

      // department
      this.filterDepartmentIdOption = (departments?.data?.data || []).map(
        item => {
          const name = item?.name

          return {
            name,
            divisionId: item?.division_id,
            id: item?.id,
          }
        },
      )

      // division
      this.filterDivisionIdOption = (divisions?.data?.data || []).map(
        item => {
          const name = item?.name
          return {
            name,
            id: item?.id,
          }
        },
      )

      // employee
      this.filterEmpIdOption = (employees?.data?.data || []).map(item => ({
        avatar: item?.avatar,
        name: item?.name,
        email: item?.email,
        mobile: item?.mobile,
        employee_number: item?.employee_number,
        id: item?.id,
        departmentId: item?.department_id,
      }))
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error',
          icon: 'BellIcon',
          variant: 'danger',
          text: error?.response?.data?.message,
        },
      })
    }
  },

  methods: {
    getCurrentDate() {
      // Input date string
      const currentDate = new Date()

      // Extract year, month, and day components
      const year = currentDate.getFullYear()
      const month = String(currentDate.getMonth() + 1).padStart(2, "0") // Add 1 to month since it's zero-based
      const day = String(currentDate.getDate()).padStart(2, "0")

      // Create the formatted date string
      return `${year}-${month}-${day}`
    },

    async loadAttendance(value) {
      if (value == 'division') {
        this.selectDepartmentId = ''
        this.selectEmpId = ''
        if (this.selectDivisionId) {
          const departments = await this.getDepartments()

          this.filterDepartmentIdOption = (departments?.data?.data || []).map(
            item => {
              const name = item?.name

              return {
                name,
                divisionId: item?.division_id,
                id: item?.id,
              }
            },
          )

          this.filterDepartmentIdOption = this.filterDepartmentIdOption.filter(
            item => item?.divisionId == this.selectDivisionId,
          )
        } else {
          const departments = await this.getDepartments()

          this.filterDepartmentIdOption = (departments?.data?.data || []).map(
            item => {
              const name = item?.name

              return {
                name,
                divisionId: item?.division_id,
                id: item?.id,
              }
            },
          )
        }
      }

      if (value == 'department') {
        this.selectEmpId = ''
        if (this.selectDepartmentId) {
          const employees = await this.getActiveEmployees()

          this.filterEmpIdOption = (employees?.data?.data || []).map(item => {
            const name = `${item?.name} (${item?.email})`
            return {
              avatar: item?.avatar,
              name: item?.name,
              email: item?.email,
              mobile: item?.mobile,
              employee_number: item?.employee_number,
              id: item?.id,
              departmentId: item?.department_id,
            }
          })

          this.filterEmpIdOption = this.filterEmpIdOption.filter(
            item => item?.departmentId == this.selectDepartmentId,
          )
        } else {
          const employees = await this.getActiveEmployees()

          this.filterEmpIdOption = (employees?.data?.data || []).map(item => ({
            avatar: item?.avatar,
            name: item?.name,
            email: item?.email,
            mobile: item?.mobile,
            employee_number: item?.employee_number,
            id: item?.id,
            departmentId: item?.department_id,
          }))
        }
      }

      this.loadItems()
    },

    async getActiveEmployees() {
      return await this.$api.get('api/users/active-all')
    },
    async getDivisions() {
      return await this.$api.get('api/divisions/all')
    },
    async getEmployeeTypes() {
      return await this.$api.get('api/employee-types/all')
    },
    async getAttendanceStatus() {
      return await this.$api.get("api/attendance-status/all");
    },
    async getDepartments() {
      return await this.$api.get('api/departments/all')
    },
    formatFnTableFormateDate(value) {
      if (value) {
        return this.$moment(value).format('ll')
      }
    },
    formatFnTableExtraLessDutyHours(value) {
      if (value) {
        return minutesToHours(value)
      }
    },
    formatFnTableExpectedDutyHours(value) {
      if (value) {
        return minutesToHours(value)
      }
    },
    formatFnTableCheckIn(value) {
      if (value) {
        return this.$moment(value, 'HH:mm:ss').format('LT')
      }
    },
    formatFnTableCheckOut(value) {
      if (value) {
        return this.$moment(value, 'HH:mm:ss').format('LT')
      }
    },
    formatFnTableTotalWorkedMinutes(value) {
      if (value) {
        return minutesToHours(value)
      }
    },
    showModal() {
      this.$bvModal.show('modal-department-form')
    },
    hiddenModal() {
      this.modelType = ''
      this.$bvModal.hide('modal-department-form')
      this.resetModal()
    },
    resetModal() {
      this.name = ''
      this.selectHeadId = ''
      this.attendanceId = ''
    },
    onShowPage(id) {
      this.$router.push({
        name: 'admin-attendance-details',
        params: { id },
      })
    },

    onShowUrlGet(id) {
      const currentUrl = window.location.href;
      return `${currentUrl}/${id}`;

    },
    // async onShow(value) {
    //   this.modelType = "editModel";
    //   this.name = value.name;
    //   this.departmentId = value.id;
    //   this.selectHeadId = value.head_id;
    //   this.selectDivisionId = value.division_id;

    //   this.showModal();
    // },

    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },

    onOpenInNewTab(id) {
    const routeData = this.$router.resolve({ name: 'admin-attendance-details', params: { id } });
    window.open(routeData.href, '_blank');
  },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },

    onSortChange(params) {
      this.updateParams({
        sort: params,
      })
      this.loadItems()
    },

    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },

    async getAttendances(params) {
      return await this.$api.get(
        'api/attendances?include=user,attendanceStatus',
        {
          params: {
            show: params.show,
            page: params.page,
            sort: params.sort,
            filterStatusId: params.filterStatus,
            filterDivisionId: params.filterDivisionId,
            filterEmpTypeId: params.filterEmpTypeId,
            filterDepartmentId: params.filterDepartmentId,
            filterEmpId: params.filterEmpId,
            filterStartDate: params.filterStartDate,
            filterEndDate: params.filterEndDate,
          },
        },
      )
    },

    async loadItems() {
      try {

        const { startDate, endDate } = formatDateRange(this.filterRangeDate)

        const [attendances] = await Promise.all([
          this.getAttendances({
            show: this.serverParams.perPage,
            page: this.serverParams.page,
            sort: this.serverParams.sort,
            filterDivisionId: this.selectDivisionId,
            filterEmpTypeId: this.selectEmpTypeId,
            filterDepartmentId: this.selectDepartmentId,
            filterEmpId: this.selectEmpId,
            filterStartDate: startDate,
            filterEndDate: endDate,
            filterStatus: this.selectStatusId,
          }),
        ])

        const data = attendances?.data?.data

        // console.log(data);
        const meta = attendances?.data?.meta

        this.totalRecords = meta?.pagination?.total
        this.rows = data
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Warning',
            icon: 'BellIcon',
            variant: 'warning',
            text: error?.response?.data?.message,
          },
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table";
@import '@core/scss/vue/libs/vue-flatpicker';
.table-custom-style {
  font-size: 14px !important;
  white-space: nowrap !important;
  min-height: 140px !important;
  tr,
  th,
  td {
    vertical-align: middle !important;
  }
}
</style>
